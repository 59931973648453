import React from "react";
import styled from "styled-components";
// Components
import ServiceBox from "../Elements/ServiceBox";
import BlogBox from '../Elements/BlogBox.jsx';

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Жилой комплекс Seapoint Residences в Дубае</h1>
            <p className="font16">
              Строящийся ЖК Seapoint Residences – это новый элитный проект от девелоперской компании Emaar. Жилой комплекс будет состоять из 2 башен и расположится на искусственном острове Emaar Beachfront, в 10 минутах езды от популярного яхтенного района Dubai Marina.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                text={
                  <span>Первоначальный взнос от <span className="extraBold">10%</span></span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                text={
                  <span>Тип строений: <span className="extraBold">Премиум</span></span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                text={
                  <span className="extraBold">Престижный район</span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                text={
                  <span className="extraBold">Красивый вид</span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                text={
                  <span>Дата сдачи<span className="extraBold"> II квартал, 2028</span></span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                text={
                  <span>Расположение:<span className="extraBold"> Эмаар Бичфронт, Дубай</span></span>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Удобства элитного жилого комплекса</h1>
            <p className="font16">
              10 000 кв.м парков и зон отдыха на площади ЖК
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex flex-wrap">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="swimming.png"
                title="Бассейн"
                // subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="parking.png"
                title="Парковка"
                // subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="restaurant.png"
                title="Ресторан"
                // subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
              />
            </ServiceBoxWrapper>

            <ServiceBoxWrapper>
              <ServiceBox
                icon="sea.png"
                title="Возле моря"
                // subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
              />
            </ServiceBoxWrapper>

            <ServiceBoxWrapper>
              <ServiceBox
                icon="exercise1.png"
                title="Спортивная площадка"
                // subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
              />
            </ServiceBoxWrapper>

            <ServiceBoxWrapper>
              <ServiceBox
                icon="grass1.png"
                title="Общественный газон"
                // subtitle="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  align-items: center;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    //flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 16.6%;
  padding: 30px 0;
  margin-bottom: 50px;
  @media (max-width: 860px) {
    width: 50%;
    text-align: center;
    padding: 20px 0;
    margin-bottom: 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
