import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="lightBg" style={{padding: '50px 0', marginBottom: 50}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">План платежей</h1>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4">
              <PriceBox>
                <span className="font18">При бронировании</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-4">
              <PriceBox>
                <span className="font18">Июнь 2023</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-4">
              <PriceBox>
                <span className="font18">Январь 2024</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-4">
              <PriceBox>
                <span className="font18">Июль 2024</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-4">
              <PriceBox>
                <span className="font18">Май 2025</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-4">
              <PriceBox>
                <span className="font18">Ноябрь 2025</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-4">
              <PriceBox>
                <span className="font18">Июнь 2026</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-4">
              <PriceBox>
                <span className="font18">Декабрь 2026</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-4">
              <PriceBox>
                <span className="font18">Июнь 2027</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
            <div className="col-xs-12 col-sm-4 col-sm-offset-4">
              <PriceBox>
                <span className="font18">Апрель 2027</span>
                <p className="font25 semiBold">10%</p>
              </PriceBox>
            </div>
          </div>
        </div>
      </div>
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Планировки и цена апартаментов</h1>
            <p>
              В проекте можно выбрать апартаменты с 1-й, 2-мя или 3-мя спальнями, а также пентхаус с 5-ю или 6-ю спальнями.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="Апартаменты с 1 спальней"
                title="От $735,000 до $985,000"
                offers={[
                  { name: "Количество квартир: 185", cheked: true },
                  { name: "Площадь: от 53 до 71 кв.м.", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="roller"
                price="Апартаменты с 2 спальнями"
                title="От $1,225,356 до $1,814,007"
                offers={[
                  { name: "Количество квартир: 175", cheked: true },
                  { name: "Площадь: от 102 до 151 кв.м.", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="roller"
                price="Апартаменты с 3 спальнями"
                title="От $2,477,943"
                offers={[
                  { name: "Количество квартир: 67", cheked: true },
                  { name: "Площадь: от 169 кв.м.", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="roller"
                price="Апартаменты с 5 спальнями"
                title="От $7,542,751"
                offers={[
                  { name: "Количество квартир: 43", cheked: true },
                  { name: "Площадь: от 488 кв.м.", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="roller"
                price="Апартаменты с 6 спальнями"
                title="От $14,595,359"
                offers={[
                  { name: "Количество квартир: 23", cheked: true },
                  { name: "Площадь: от 1090 кв.м.", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;
const TableBoxBig = styled.div`
  width: 47%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;
const PriceBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgb(200 200 200)  ;
  -webkit-box-shadow: 0 0 5px rgb(200 200 200)  ;
  -moz-box-shadow: 0 0 5px rgb(200 200 200)  ;
  padding: 10px 20px 5px;
  align-items: center;
  margin-bottom: 15px;
`;




