import React, { useEffect } from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import { Link } from 'react-scroll';
import AddImage1 from '../../assets/img/add/278x405.jpg';
import AddImage2 from '../../assets/img/add/174x293.jpg';
import AddImage3 from '../../assets/img/add/116x130.jpg';
import AddImage4 from '../../assets/img/add/174x198.jpg';

export default function Project() {
  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    }
  })

  function isBottom(el) {
    return el?.getBoundingClientRect().bottom - 200 <= window.innerHeight;
  }

  const trackScrolling = () => {
    const wrappedElement = document.getElementById('projectImagesContainer');
    if (isBottom(wrappedElement)) {
      console.log('header bottom reached');
      document.getElementById('projectImages').classList.add('animate')
      document.removeEventListener('scroll', trackScrolling);
    }
  };
  return (
    <Wrapper id="project">
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter" id="projectImagesContainer">
            <AddLeft>
              {/*<h4 className="font15 semiBold">A few words about company</h4>*/}
              <h2 className="font40 extraBold">О проекте</h2>
              <p className="font14">
                Emaar Beachfront — это новая локация для жизни, которая расположена между престижными районами Пальма Джумейра и Дубай Марина. Основная идея проекта заключается в создании частного экзотического острова с городской инфраструктурой, где жителям будут доступны рестораны высокой кухни, торговые центры, отели 5* и собственный пляж. Здесь вы можете припарковать свою яхту прямо под окнами своей квартиры. На острове будет собственный пляж протяженностью 1,5 километра с белоснежным песком.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                <div style={{ width: "190px" }}>
                  <Link to="contact" smooth={true}><FullButton title="Оставить заявку" /></Link>
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <Link to="contact" smooth={true}>
                    <FullButton title="Обратная связь" border />
                  </Link>
                </div>
              </ButtonsRow>
            </AddLeft>
            <AddRight id="projectImages">
              <AddRightInner>
                <div className="flexNullCenter">
                  <AddImgWrapp1 className="flexCenter">
                    <img src={AddImage1} alt="office" />
                  </AddImgWrapp1>
                  <AddImgWrapp2>
                    <img src={AddImage2} alt="office" />
                  </AddImgWrapp2>
                </div>
                <div className="flexNullCenter">
                  <AddImgWrapp3>
                    <img src={AddImage3} alt="office" />
                  </AddImgWrapp3>
                  <AddImgWrapp4>
                    <img src={AddImage4} alt="office" />
                  </AddImgWrapp4>
                </div>
              </AddRightInner>
            </AddRight>
          </Advertising>
        </div>
      </div>
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Почему Seapoint Residences?</h1>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Образ жизни"
                text={
                  <span>
                    Апартаменты в Seapoint Residences станут идеальным местом для роскошного стиля жизни на побережье Персидского залива. Из нового жилого комплекса будет прямой выход к частному пляжу, где можно купаться в море, загорать и заниматься водными видами спорта. В ресторанах и кафе, расположенных на первых этажах комплекса, жители смогут с комфортом проводить время за вкусными завтраками, обедами и ужинами.
                    <br/><br/>Из комплекса будет оборудован удобный выход к прогулочному бульвару, который находится между многоквартирными домами района. Здесь вас ждут высококлассные рестораны на любой вкус, модные магазины и пространства для отдыха под открытым небом.
                  </span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Преимущества ЖК"
                text={
                  <>
                    Одним из ключевых преимуществ нового ЖК Seapoint Residences станет наличие частного пляжа, предусмотренного исключительно для резидентов. Эксклюзивной особенностью также станет огромный пейзажный бассейн площадью 400 м2. Это самый большой панорамный бассейн во всем районе.
                    <br/><br/>Рядом с жилым комплексом находится яхтенная марина и яхт-клуб, что делает его идеальным местом для любителей морских прогулок и яхтенного спорта. Здесь можно как арендовать яхту, так и оставить частную лодку для обслуживания.
                    <br/><br/>Кроме того, из жилого комплекса можно за 5 минут выехать на крупную автомобильную дорогу Sheikh Zayed. Магистраль идет вдоль береговой линии и ведет в центр города.
                  </>
                }
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Завершение строительства"
                text={
                  <span>
                    Новый жилой комплекс был недавно анонсирован девелопером. Продажи недвижимости в ЖК стартовали в апреле 2023 года. Ориентировочная дата передачи жилого комплекса в эксплуатацию – апрель 2028 года. Проектом занимается известная девелоперская компания с огромным опытом работы и множеством элитных проектов в Дубае и других городах мира. Это говорит о том, что покупатели квартир в строящемся ЖК Seapoint Residences по цене застройщика могут не сомневаться в высоком качестве готовой недвижимости. Также важно отметить, что застройщик всегда четко соблюдает заявленные сроки возведения зданий.
                  </span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Недвижимость в Seapoint Residences для инвестиций"
                text={
                  <span>
                    Покупка недвижимости в ЖК Seapoint Residences на стадии строительства – это отличный способ сохранить и приумножить ваш капитал. Инвесторы в недвижимость от застройщика Emaar Properties в Дубае могут приобрести как единичные квартиры, так и вложить деньги в половину этажа.
                    <br/><br/>Курортный район, где возводится новый ЖК, – перспективное место для арендного бизнеса. Минимальная стоимость годовой аренды квартиры с 1 спальней здесь начинается от 130 000 AED (35 500 $). При этом элитные резиденции с 3 спальнями можно арендовать по цене 650 000 AED (177 000 $) в год. Таким образом, владельцы апартаментов в новом ЖК смогут рассчитывать на высокую рентабельность инвестиций. Для примера, жилая недвижимость в районе Emaar Beachfront приносит до 8% годовых.
                  </span>
                }
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-12 flexCenter flex x100" style={{marginTop: 30}}>
              <Link to="contact" smooth={true}><FullButton title="Оставить заявку" /></Link>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
  #projectImages {
    display: none;
  }
  #projectImages.animate {
    display: block;
    animation-duration: 0.5s;
    animation-name: animate-pop;
    animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
  }

  @keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

